import React, {useEffect, useState} from 'react'
import ApiClient from "../ApiClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {Button} from "react-bulma-components";

const FacebookAccessToken : React.FC = () => {

    const [token, setToken] = useState("")

    useEffect(() => {
        (async() => {
            const client: ApiClient = new ApiClient()
            const token : any = await client.getToken()
            setToken(token.value)
        })()
    }, [] )

    return (
        <div className={"container"}>
            <section className={"section"}>
                <h1 className={"title"}><FontAwesomeIcon icon={faFacebook}/> Access Token</h1>
                <div className={"container mb-6"} style={{wordWrap:"break-word"}}>
                    { token }
                </div>
                <Button color={"primary"}>PacketProxyに送る</Button>
            </section>
        </div>
    )
}

export default FacebookAccessToken