import React from 'react'

const Apple : React.FC = () => {
    const apple = "https://appleid.apple.com/auth/authorize?" +
        "client_id=com.funacs.signintest.web&" +
        "redirect_uri=https%3A%2F%2Ftoken-api.funacs.com%2Fapple%2Fid_token&" +
        "response_type=code%20id_token&" +
        "response_mode=form_post";
    window.location.href = apple;
    return (
        <div>
            Redirecting...
        </div>
    )
}

export default Apple
