
class PacketProxyClient {

    public async getToken() : Promise<any> {
        return this.callApi("GET", '/token')
    }

    public async send(token: string) : Promise<any> {
        return this.callApi("POST", '/token', `{ "token": "${token}" }`)
    }

    private async callApi(method: string, path: string, jsonBody?: string): Promise<any> {
        let headers: HeadersInit = {}
        let params: RequestInit = {
            method: method,
            headers: headers,
        }
        if (jsonBody && jsonBody.length > 0) {
            headers["Content-Type"] = "application/json"
            params.body = jsonBody
        }
        const response = await fetch(`http://localhost:32350${path}`, params)
        if (!response.ok) {
            throw new Error(`HTTP status = ${response.statusText}`)
        }
        return response.json()
    }
}

export default PacketProxyClient