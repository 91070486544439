import React from 'react'

const FacebookCodeEntryPoint : React.FC = () => {

    const facebook = "https://www.facebook.com/dialog/oauth?" +
        "client_id=579793059852792&" +
        "redirect_uri=https://token-api.funacs.com/facebook/code&" +
        "state=1234&" +
        "response_type=code"

    window.location.href = facebook;
    return (
        <div>
            Redirecting...
        </div>
    )
}

export default FacebookCodeEntryPoint
