import React from 'react'
import {Link} from 'react-router-dom'
import 'bulma/css/bulma.min.css';
import {Button} from "react-bulma-components";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faApple, faFacebook, faGoogle, faLine, faTwitter} from '@fortawesome/free-brands-svg-icons';

const Top : React.FC = () => {
    return (
        <>
            <div className={"container"}>
                <section className={"section pb-2"}>
                    <h1 className={"title"}><FontAwesomeIcon icon={faApple}/> Sign In with Apple</h1>
                    <Button className={"has-text-weight-bold"} color={"primary"} to={"/apple"} renderAs={Link}>
                        id_token 取得
                    </Button>
                </section>
                <section className={"section pb-2"}>
                    <h1 className={"title"}><FontAwesomeIcon icon={faGoogle}/> Google Login</h1>
                    <Button className={"has-text-weight-bold"} color={"primary"} to={"/google"} renderAs={Link}>
                        id_token 取得
                    </Button>
                </section>
                <section className={"section pb-2"}>
                    <h1 className={"title"}><FontAwesomeIcon icon={faFacebook}/> Facebook Login</h1>
                    <Button className={"has-text-weight-bold mr-4"} color={"primary"} to={"/facebook/code/entry_point"} renderAs={Link}>
                        code 取得
                    </Button>
                    <Button className={"has-text-weight-bold"} color={"primary"} to={"/facebook/access_token/entry_point"} renderAs={Link}>
                        access_token 取得
                    </Button>
                </section>
                <section className={"section pb-2"}>
                    <h1 className={"title"}><FontAwesomeIcon icon={faLine}/> LINE Login</h1>
                    <Button className={"has-text-weight-bold mr-4"} color={"primary"} to={"/line/code/entry_point"} renderAs={Link}>
                        code 取得
                    </Button>
                    <Button className={"has-text-weight-bold mr-4"} color={"primary"} to={"/line/access_token/entry_point"} renderAs={Link}>
                        access_token 取得
                    </Button>
                    <Button className={"has-text-weight-bold mr-4"} color={"primary"} to={"/line/id_token/entry_point"} renderAs={Link}>
                        id_token 取得
                    </Button>
                </section>
                <section className={"section pb-2"}>
                    <h1 className={"title"}><FontAwesomeIcon icon={faTwitter}/> Twitter Login</h1>
                    <p>
                        まだ実装していません
                    </p>
                </section>
            </div>
        </>
    )
}

export default Top