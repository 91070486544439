import React from 'react'

const LineAccessTokenEntryPoint : React.FC = () => {
    const line = "https://access.line.me/oauth2/v2.1/authorize?" +
        "response_type=code&" +
        "client_id=1570783395&" +
        "redirect_uri=https%3A%2F%2Ftoken-api.funacs.com%2Fline%2Faccess_token&" +
        "scope=profile%20openid&" +
        "state=34214xyz&" +
        "nonce=09876xyz"

        window.location.href = line;
    return (
        <div>
            Redirecting...
        </div>
    )
}

export default LineAccessTokenEntryPoint
