import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import 'bulma/css/bulma.min.css'
import Top from './features/Top'
import Apple from './features/entrypoints/Apple'
import AppleIdToken from './features/landings/AppleIdToken'
import Google from "./features/entrypoints/Google";
import GoogleIdToken from "./features/landings/GoogleIdToken";
import LineCodeEntryPoint from "./features/entrypoints/LineCodeEntryPoint";
import LineIdToken from "./features/landings/LineIdToken";
import FacebookAccessTokenEntryPoint from "./features/entrypoints/FacebookAccessTokenEntryPoint";
import FacebookAccessToken from "./features/landings/FacebookAccessToken";
import FacebookCodeEntryPoint from "./features/entrypoints/FacebookCodeEntryPoint";
import FacebookCode from "./features/landings/FacebookCode";
import LineIdTokenEntryPoint from "./features/entrypoints/LineIdTokenEntryPoint";
import LineCode from "./features/landings/LineCode";
import LineAccessTokenEntryPoint from "./features/entrypoints/LineAccessTokenEntryPoint";
import LineAccessToken from "./features/landings/LineAccessToken";

const App : React.FC = () => {
    return (
        <Router>
            <section className="hero is-small is-link">
                <h1 className={"title mt-3 mb-3 ml-3"}>
                    <a href={"/"}>トークン取得サイト</a>
                </h1>
            </section>
            <Switch>
                <Route path={"/apple"} exact>
                    <Apple />
                </Route>
                <Route path={"/apple/id_token"} exact>
                    <AppleIdToken />
                </Route>
                <Route path={"/google"} exact>
                    <Google />
                </Route>
                <Route path={"/google/id_token"} exact>
                    <GoogleIdToken />
                </Route>
                <Route path={"/line/code/entry_point"} exact>
                    <LineCodeEntryPoint />
                </Route>
                <Route path={"/line/code"} exact>
                    <LineCode />
                </Route>
                <Route path={"/line/access_token/entry_point"} exact>
                    <LineAccessTokenEntryPoint />
                </Route>
                <Route path={"/line/access_token"} exact>
                    <LineAccessToken />
                </Route>
                <Route path={"/line/id_token/entry_point"} exact>
                    <LineIdTokenEntryPoint />
                </Route>
                <Route path={"/line/id_token"} exact>
                    <LineIdToken />
                </Route>
                <Route path={"/facebook/code/entry_point"} exact>
                    <FacebookCodeEntryPoint />
                </Route>
                <Route path={"/facebook/code"} exact>
                    <FacebookCode />
                </Route>
                <Route path={"/facebook/access_token/entry_point"} exact>
                    <FacebookAccessTokenEntryPoint />
                </Route>
                <Route path={"/facebook/access_token"} exact>
                    <FacebookAccessToken />
                </Route>
                <Route path="(/)?" exact>
                    <Top />
                </Route>
            </Switch>
        </Router>
    );
}

export default App
