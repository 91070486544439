import React from 'react'

const Google : React.FC = () => {
    const google = "https://accounts.google.com/o/oauth2/auth?" +
        "client_id=600388043689-chjkues8o03jnd33aa3qj8aqdn3auvf2.apps.googleusercontent.com&" +
        "redirect_uri=https%3A%2F%2Ftoken-api.funacs.com%2Fgoogle%2Fid_token&" +
        "scope=email&" +
        "response_type=id_token&" +
        "approval_prompt=force&" +
        "flowName=GeneralOAuthFlow&" +
        "response_mode=form_post"
    window.location.href = google;
    return (
        <div>
            Redirecting...
        </div>
    )
}

export default Google
