
class ApiClient {

    public async getToken() : Promise<any> {
        return this.callApi("GET", '/token')
    }

    private async callApi(method: string, path: string, jsonBody?: string): Promise<any> {
        let headers: HeadersInit = {}
        let params: RequestInit = {
            method: method,
            headers: headers,
            credentials: "include",
        }
        if (jsonBody && jsonBody.length > 0) {
            headers["Content-Type"] = "application/json"
            params.body = jsonBody
        }
        const response = await fetch(`https://token-api.funacs.com${path}`, params)
        if (!response.ok) {
            throw new Error(`HTTP status = ${response.statusText}`)
        }
        return response.json()
    }
}

export default ApiClient
